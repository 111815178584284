<template>
    <div class="app-container" v-loading.body="isLoading">
      <el-card v-if="!isLoading" class="payment-status-card">
        <div class="payment-status-card__card-body">
          <v-icon size="64px" :name="content.icon.name" :color="content.icon.color"/>
          <h2 class="heading page-heading">
            {{ content.heading }}
          </h2>
          <span>
            {{ content.description }}
          </span>
          <div class="payment-status-card__card-actions">
            <v-button type="primary" @click="goToUnitPage">Wróć do zbiórki</v-button>
          </div>
        </div>
      </el-card>
    </div>
</template>

<script>
import VIcon from '../../components/ui/v-icon/VIcon'
import VButton from '../../components/ui/v-button/VButton'
import {
  STATUS_WAITING,
  STATUS_VERIFIED,
  STATUS_ERROR
} from '../../shared/constants/payment-statuses'

import {
  NOT_FOUND_ROUTE_NAME,
  UNIT_PURPOSES_ROUTE_NAME
} from '../../shared/constants/app-routes-names'

import {fetchOrderStatusInfo} from '../shared/services/payment-service'

const COLOR_ERROR = '#f56c6c'
const COLOR_SUCCESS = '#39d6c1'
const COLOR_WARNING = '#e6a23c'

export default {
  name: 'CompletePayment',
  components: {VButton, VIcon},
  data() {
    return {
      isLoading: true,
      order: {
        status: ''
      }
    }
  },
  created() {
    this.fetchOrder()
  },
  methods: {
    fetchOrder() {
      const { orderID } = this.$route.params
      fetchOrderStatusInfo(orderID)
        .then((res) => {
          this.order = res
        })
        .catch(() => {
            this.$router.push({name: NOT_FOUND_ROUTE_NAME})
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goToUnitPage() {
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID: this.order.unitId
        }
      })
    }
  },
  computed: {
    content() {
      const content = {
        [STATUS_WAITING]: {
          icon: {
            name: 'access_time_filled',
            color: COLOR_WARNING
          },
          heading: this.$t(`page_complete_payment.${STATUS_WAITING}.title`),
          description: this.$t(`page_complete_payment.${STATUS_WAITING}.description`)
        },
        [STATUS_VERIFIED]: {
          icon: {
            name: 'check_circle',
            color: COLOR_SUCCESS
          },
          heading: this.$t(`page_complete_payment.${STATUS_VERIFIED}.title`),
          description: this.$t(`page_complete_payment.${STATUS_VERIFIED}.description`)
        },
        [STATUS_ERROR]: {
          icon: {
            name: 'error',
            color: COLOR_ERROR
          },
          heading: this.$t(`page_complete_payment.${STATUS_ERROR}.title`),
          description: this.$t(`page_complete_payment.${STATUS_ERROR}.description`)
        },
      }

      return content[this.order.status]
    }
  }
}
</script>

<style scoped lang="scss">
.page-wrapper {
  min-height: 100%;
}
.payment-status-card {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  &__card-body {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__card-actions {
    margin-top: 2em;
  }
}
.page-heading {
  margin: 0.4em 0;
}
</style>
